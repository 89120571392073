// import Logo from "../asset/header/logo.svg";
import GalleryImg1 from "../asset/gallery/image1.jpg";
import GalleryImg2 from "../asset/gallery/image2.jpg";
import GalleryImg3 from "../asset/gallery/image3.jpg";
import GalleryImg4 from "../asset/gallery/image4.jpg";
import GalleryImg5 from "../asset/gallery/image5.jpg";
import GalleryImg6 from "../asset/gallery/image6.jpg";
import GalleryImg7 from "../asset/gallery/image7.jpg";
import GalleryImg8 from "../asset/gallery/image8.jpg";
import QuoteImg from "../asset/testimonial/quote.svg";
import { GrFacebookOption } from "react-icons/gr";
import { IoMdArrowForward } from "react-icons/io";
import { FiSend } from "react-icons/fi";
import { FaPlay, FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from "react-icons/fa";
import {
  IoLogoInstagram,
  IoLogoPinterest,
  IoLogoTwitter,
  IoLogoYoutube,
} from "react-icons/io";

export const headerData = {};

export const navData = {
  items: [
    { href: "/", name: "Home" },
    { href: "/about", name: "About" },
    { href: "/gallery", name: "Gallery" },
    { href: "/inception", name: "Inception" },
    { href: "/contacts", name: "Contact" },
  ],
};

export const socialData = [
  {
    href: "https://www.facebook.com/xtremetattoosbanglore",
    icon: <GrFacebookOption />,
  },
  {
    href: "https://www.instagram.com/xtreme_tattoos_bangalore/",
    icon: <IoLogoInstagram />,
  },
  /* { href: "/", icon: <IoLogoPinterest /> },
  { href: "/", icon: <IoLogoTwitter /> },
  { href: "/", icon: <IoLogoYoutube /> }, */
];

export const heroData = {
  title: "I’m Void Sir.",
  subtitle:
    "Tattoos have their own unique power and magic. They not only beautify the body but also the psyche.",
  btnText: "read more",
  btnIcon: <IoMdArrowForward />,
};

export const aboutData = {
  title: "My Story:",
  subtitle1:
    "At Xtreme Tattoo Studios, I founded a haven fueled by a fervent passion for artistic expression and an unwavering commitment to providing individuals with a transformative canvas for their unique narratives. More than just a tattoo studio, it's a collaborative sanctuary where stories come alive through the meticulous dance of ink and skin. We believe in crafting chapters, visual tales etched with precision and care, encapsulating the essence of personal narratives. Our welcoming environment fosters collaboration between skilled artists and clients, ensuring that every visit leaves an indelible mark, both on the skin and in the hearts of those who entrust us with their stories.",
  subtitle2: "",
  btnText: "Know more",
  btnIcon: <IoMdArrowForward />,
};

export const galleryData = {
  title: "Check my gallery:",
  btnText: "View all",
  btnIcon: <IoMdArrowForward />,
  images: [
    {
      src: GalleryImg1,
      original: GalleryImg1,
      width: 350,
      height: 500,
    },
    {
      src: GalleryImg2,
      original: GalleryImg2,
      width: 465,
      height: 540,
    },
    {
      src: GalleryImg3,
      original: GalleryImg3,
      width: 450,
      height: 480,
    },
    {
      src: GalleryImg4,
      original: GalleryImg4,
      width: 465,
      height: 540,
    },
    {
      src: GalleryImg5,
      original: GalleryImg5,
      width: 465,
      height: 540,
    },
    {
      src: GalleryImg6,
      original: GalleryImg6,
      width: 464,
      height: 412,
    },
    {
      src: GalleryImg7,
      original: GalleryImg7,
      width: 465,
      height: 540,
    },
    {
      src: GalleryImg8,
      original: GalleryImg8,
      width: 465,
      height: 412,
    },
  ],
};

export const interviewData = {
  title:
    "“Consider what you desire. Your tattoo artist will never tell you what tattoo to have.”",
  btnText: "Watch it now",
  btnIcon: <FaPlay />,
};

export const testimonialData = [
  {
    quoteImg: QuoteImg,
    message:
      "I can't express enough gratitude to Xtreme Tattoo Studios for turning my vision into a masterpiece. The skilled artists at the studio not only demonstrated exceptional talent but also created a warm and collaborative environment.",
    name: "Rohit Kumar",
    occupation: "Software Engineer",
    scroll: ">>>",
  },
  {
    quoteImg: QuoteImg,
    message:
      "Xtreme Tattoo Studios transformed my vision into a living masterpiece. The artists' attention to detail and commitment to customer satisfaction made the entire process a joy.",
    name: "Rajendra Prasad",
    occupation: "Bcom Student",
    scroll: "<<<",
  },
  {
    quoteImg: QuoteImg,
    message:
      "I can't thank Xtreme Tattoo Studios enough for the incredible tattoo experience. The artists not only brought my concept to life but also added their artistic flair, creating a piece that exceeded my expectations.",
    name: "Shankar",
    occupation: "Designer",
    scroll: "<<<",
  },
  {
    quoteImg: QuoteImg,
    message:
      "Xtreme Tattoo Studios is not just a studio; it's an artistic haven. The talented team took my vague ideas and transformed them into a work of art that tells my story beautifully.",
    name: "Shashank",
    occupation: "Guitarist",
    scroll: "<<<",
  },
  {
    quoteImg: QuoteImg,
    message:
      "I had an amazing experience at Xtreme Tattoo Studios. The artists listened attentively to my ideas and offered valuable insights, resulting in a tattoo that exceeded my expectations",
    name: "Douglas",
    occupation: "Lead singer",
    scroll: "<<<",
  },
];

export const contactData = {
  title: "Get in touch with me:",
  info: [
    {
      title: "Banashankari Location",
      subtitle: "",
      address: {
        icon: <FaMapMarkerAlt />,
        name: "No.8 , 1st floor, Mount Joy Road, Hanumantha nagar, Banashankari 1st stage,, Bengaluru, Karnataka 560019",
      },
      phone: {
        icon: <FaPhoneAlt />,
        number: "+919886652084",
        number1: "+918884888805",
      },
      email: {
        icon: <FaEnvelope />,
        address: "srinivasaishani@gmail.com",
      },
      link: "Get Hanumanth Nagar Branch Location",
      url: "https://www.google.com/maps/place/Xtreme+Tattoo+Studio/@12.9401357,77.5596338,17z/data=!4m10!1m2!2m1!1sXtreme+Tattoo+Studio!3m6!1s0x3bae158b0229fb23:0x7ddb6fdbeed7986f!8m2!3d12.9401357!4d77.5643974!15sChRYdHJlbWUgVGF0dG9vIFN0dWRpb1oWIhR4dHJlbWUgdGF0dG9vIHN0dWRpb5IBC3RhdHRvb19zaG9wmgEjQ2haRFNVaE5NRzluUzBWSlEwRm5TVU53YkV0aUxVMVJFQUXgAQA!16s%2Fg%2F11b5wrp9hl?entry=ttu",
    },
    {
      title: "Jayanagar Location",
      subtitle: "",
      address: {
        icon: <FaMapMarkerAlt />,
        name: "#12 9th main road 4th Block, Near: RTO office,, Opposite janata bazaar, next to Chouhan and sons uniform shop, Jayanagar, Bengaluru, Karnataka 560011",
      },
      phone: {
        icon: <FaPhoneAlt />,
        number: "+919886652084",
        number1: "+918884888805",
      },
      email: {
        icon: <FaEnvelope />,
        address: "srinivasaishani@gmail.com",
      },
      link: "Get Jayanagar Branch Location",
      url: "https://www.google.com/maps/place/Xtreme+Tattoo+Studio/@12.9293334,77.5789748,17z/data=!4m10!1m2!2m1!1sXtreme+Tattoo+Studio!3m6!1s0x3bae159842013c71:0xa78e5f34e1efc4b4!8m2!3d12.9293334!4d77.5837384!15sChRYdHJlbWUgVGF0dG9vIFN0dWRpb1oWIhR4dHJlbWUgdGF0dG9vIHN0dWRpb5IBC3RhdHRvb19zaG9wmgEkQ2hkRFNVaE5NRzluUzBWSlEwRm5TVU53TjNSbGNUaG5SUkFC4AEA!16s%2Fg%2F11bwl3q68l?entry=ttu",
    },
  ],
  form: {
    name: "Write your name here",
    email: "Write your email address",
    message: "Write your messages here",
    btnText: "Send it",
  },
};

export const footerData = {
  about: {
    title: "About Xtreme Tattoo Studio",
    subtitle:
      "Discover a world of artistic excellence at Xtreme Tattoo Studio's Jayanagar branch, where skilled craftsmanship and a welcoming atmosphere converge to turn your tattoo visions into vibrant reality.",
    address: {
      icon: <FaMapMarkerAlt />,
      name: "#12 9th main road 4th Block, Near: RTO office, Opposite janata bazaar, next to Chouhan and sons uniform shop, Jayanagar, Bengaluru, Karnataka 560011",
    },
    phone: {
      icon: <FaPhoneAlt />,
      number: "+919886652084",
    },
    email: {
      icon: <FaEnvelope />,
      address: "srinivasaishani@gmail.com",
    },
  },
  links: {
    title: "Useful links",
    items: [
      { href: "/about", name: "About me" },
      { href: "/gallery", name: "My gallery" },
      { href: "/inception", name: "My services" },
      {
        href: "https://api.whatsapp.com/send?phone=919886652084&text=Hey%20Xtreme%20Tattoo%20Studio%2C%20I%27d%20like%20to%20book%20an%20appointment%20for%20a%20tattoo%2C%20what%27s%20your%20availability%3F%20Thanks!",
        name: "Contact me",
      },
    ],
  },
  program: {
    title: "Working Time",
    items: [
      { name: "Mon - Sun / 10:00am - 9:00pm" },
      { name: "Open All 7 days" },
    ],
  },
  program1: {
    title: "Social Media",
    items: [
      {
        imgLink:
          "https://www.google.com/url?sa=i&url=https%3A%2F%2Fpngtree.com%2Ffree-png-vectors%2Ffacebook-logo&psig=AOvVaw1PnxERKRRN5OWngUPKd76K&ust=1705848750557000&source=images&cd=vfe&ved=0CBMQjRxqFwoTCKDD_feb7IMDFQAAAAAdAAAAABAE",
        url: "/facebook.png",
      },
      { imgLink: "facebook-img-link", url: "facebook-url" },
      { imgLink: "twitter-img-link", url: "twitter-url" },
      { imgLink: "linkedin-img-link", url: "linkedin-url" },
    ],
  },

  newsletter: {
    title: "Newsletter",
    subtitle:
      "Subscribe to our newsletter and get our newest updates right on your inbox.",
    form: {
      placeholder: "Your email address",
      icon: <FiSend />,
    },
  },
};
