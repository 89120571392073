import React from "react";

const WhatsAppButton = () => {
  return (
    <a
      id="whatsapp-button"
      href="https://api.whatsapp.com/send?phone=919886652084&text=Hey%20Xtreme%20Tattoo%20Studio%2C%20I%27d%20like%20to%20book%20an%20appointment%20for%20a%20tattoo%2C%20what%27s%20your%20availability%3F%20Thanks!" // Replace with your WhatsApp number
      target="_blank"
      rel="noopener noreferrer"
      className="fixed bottom-4 right-4  text-white rounded-full p-3 cursor-pointer transition duration-300 hover:bg-green-700"
    >
      <img className="w-[60px]" src="/whatsapp.png" alt="" />
    </a>
  );
};

export default WhatsAppButton;
