import React, { useEffect, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";
import tattoo2 from "../../asset/landingPage/tattoo2.jpg";
import tattoo3 from "../../asset/landingPage/tattoo3.jpg";
// import useOnScreen from "../../hook/useOnScreen";

const LandingPage = () => {
  const { pathname } = useLocation();
  const landingRef = useRef(null);
  //   const nav = useNavigate();

  //   const isMounted = useOnScreen(landingRef);
  //   console.log(isMounted);

  const mobileScreen = useMediaQuery({
    query: "(max-width: 640px)",
  });

  useEffect(() => {
    if (pathname === "/")
      landingRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [pathname]);
  //   console.log(pathname);
  return (
    <div
      ref={landingRef}
      className="h-screen w-full relative flex flex-col items-center justify-between text-white"
    >
      {mobileScreen ? (
        <img
          className="absolute top-0 left-0 w-full h-full brightness-50"
          src={tattoo2}
          alt=""
        />
      ) : (
        <img
          className="absolute top-0 left-0 w-full h-full brightness-50 object-center object-cover xl:object-fill "
          src={tattoo3}
          alt=""
        />
      )}
      <main className="z-30 flex flex-1 flex-col items-center justify-center gap-4 xl:gap-10">
        <h1 className="block text-[2rem] xl:text-[8rem] font-bold w-full">
          Xtreme Tattoo Studio
        </h1>
        <h2 className="text-xl lg:pt-10 xl:text-3xl">
          Where Ink Meets Innovation
        </h2>
        <a
          target="blank"
          href="https://api.whatsapp.com/send?phone=919886652084&text=Hey%20Xtreme%20Tattoo%20Studio%2C%20I%27d%20like%20to%20book%20an%20appointment%20for%20a%20tattoo%2C%20what%27s%20your%20availability%3F%20Thanks!"
        >
          <button class="bg-white text-black rounded-full px-4 py-2 sm:px-6 sm:py-2 hover:bg-gray-200 hover:text-gray-800 flex justify-center items-center gap-4">
            Book an Appointment{" "}
            {/*  <img className="w-[30px]" src="/whatsapp.png" alt="" /> */}
          </button>
        </a>
      </main>
      <footer className="z-30 text-3xl animate-pulse mb-[100px]">
        Scroll down
      </footer>
    </div>
  );
};

export default LandingPage;
