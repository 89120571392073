import React, { useRef, useEffect } from "react";
import { contactData } from "../../data/data";
import { motion } from "framer-motion";
import { fadeIn } from "../../utils/varients";
import { useLocation } from "react-router-dom";

const Contact = () => {
  const { title, info, form } = contactData;
  const contactRef = useRef(null);
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === "/contacts")
      contactRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [pathname]);

  return (
    <section ref={contactRef} className="section">
      <div className="container mx-auto">
        <div className="flex flex-col xl:flex-row gap-y-16">
          {/* text */}
          <motion.div
            variants={fadeIn("right")}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.4 }}
            className="flex-1"
          >
            {/* title */}
            <h2 className="h2 max-w-[1200px]">{title}</h2>
            {/* info items */}
            <div className="flex flex-col xl:flex-row gap-x-28 gap-y-16 xl:gap-y-0">
              {info.map((item, index) => {
                // destructure item
                const { title, subtitle, address, phone, email, link, url } =
                  item;
                return (
                  // item
                  <div key={index}>
                    {/* title */}
                    <div className="font-primary uppercase font-medium text-xl mb-3">
                      {title}
                    </div>
                    {/* subtitle */}
                    <div className="mb-6 text-[#333] leading-[187%] tracking-[0.02em]">
                      {subtitle}
                    </div>
                    {/* address, phone & email */}
                    <div className="flex flex-col gap-y-3 mb-8">
                      {/* address */}
                      <div className="flex items-center gap-[10px]">
                        <div>
                          <a href={url}>{address.icon}</a>
                        </div>
                        <div className="font-medium">
                          <a href={url}>{address.name}</a>
                        </div>
                      </div>
                      {/* phone */}
                      <div className="flex items-center gap-[10px]">
                        <div>{phone.icon}</div>
                        <div className="font-medium">{phone.number}</div>
                      </div>
                      <div className="flex items-center gap-[10px]">
                        <div>{phone.icon}</div>
                        <div className="font-medium">{phone.number1}</div>
                      </div>
                      {/* email */}
                      <div className="flex items-center gap-[5px]">
                        <div>{email.icon}</div>
                        <div className="font-medium">{email.address}</div>
                      </div>

                      <div className="flex items-center gap-[5px]">
                        <div>{email.icon}</div>
                        <div className="font-medium">srini1826@gmail.com</div>
                      </div>
                    </div>
                    {/* link */}
                    <a
                      style={{ cursor: "pointer" }}
                      className="font-medium border-b border-dark pb-[5px]"
                      href={url}
                    >
                      {link}
                    </a>
                  </div>
                );
              })}
            </div>
          </motion.div>
          {/* form */}
          <motion.div
            variants={fadeIn("left")}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.4 }}
            className="flex-1 xl:pl-[40px]  justify-center items-center hidden"
          >
            <form className="flex flex-col gap-y-10 w-full">
              <input
                className="border-b border-dark placeholder:text-[#555] italic tracking-[0.06em] outline-none pb-4"
                type="text"
                placeholder={form.name}
              />
              <input
                className="border-b border-dark placeholder:text-[#555] italic tracking-[0.06em] outline-none pb-4"
                type="text"
                placeholder={form.email}
              />
              <input
                className="border-b border-dark placeholder:text-[#555] italic tracking-[0.06em] outline-none pb-4 xl:mb-[30px]"
                type="text"
                placeholder={form.message}
              />
              <button className="self-start btn btn-sm btn-dark">
                {form.btnText}
              </button>
            </form>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
