import React, { useEffect, useRef } from "react";
import { footerData } from "../../data/data";
import { motion } from "framer-motion";
import { fadeIn } from "../../utils/varients";
import { useLocation } from "react-router-dom";
import Socials from "../TopBar/Social";

const staggerContainer = {
  hidden: {},
  show: {
    transition: {
      staggerChildren: 0.3,
      delayChildren: 0.2,
    },
  },
};

const Footer = () => {
  // destructure footer data
  const { about, links, program, newsletter, program1 } = footerData;

  const footerRef = useRef(null);
  const { pathname } = useLocation();
  useEffect(() => {
    if (pathname === "/footer")
      footerRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [pathname]);

  return (
    <footer ref={footerRef} className="bg-dark section">
      <div className="container mx-auto">
        {/* grid */}
        <motion.div
          variants={staggerContainer}
          initial={"hidden"}
          whileInView={"show"}
          viewport={{ once: false, amount: 0.1 }}
          className="flex flex-col lg:flex-row lg:justify-between text-white gap-x-5 gap-y-14"
        >
          {/* about */}
          <motion.div
            variants={fadeIn("up")}
            className="flex-1 flex flex-col gap-y-6"
          >
            {/* title */}
            <div className="font-primary text-xl uppercase tracking-[0.08em]">
              {about.title}
            </div>
            {/* subtitle */}
            <div className="leading-relaxed text-[#DBDBDB]">
              {about.subtitle}
            </div>
            {/* address & phone & email */}
            <div className="flex flex-col gap-y-4  text-[#DBDBDB]">
              {/* address */}
              <div className="flex items-center gap-x-[10px]">
                <div>
                  <a href="https://www.google.com/maps/place/Xtreme+Tattoo+Studio/@12.9293334,77.5789748,17z/data=!4m10!1m2!2m1!1sXtreme+Tattoo+Studio!3m6!1s0x3bae159842013c71:0xa78e5f34e1efc4b4!8m2!3d12.9293334!4d77.5837384!15sChRYdHJlbWUgVGF0dG9vIFN0dWRpb1oWIhR4dHJlbWUgdGF0dG9vIHN0dWRpb5IBC3RhdHRvb19zaG9wmgEkQ2hkRFNVaE5NRzluUzBWSlEwRm5TVU53TjNSbGNUaG5SUkFC4AEA!16s%2Fg%2F11bwl3q68l?entry=ttu">
                    {about.address.icon}
                  </a>
                </div>
                <div>
                  <a href="https://www.google.com/maps/place/Xtreme+Tattoo+Studio/@12.9293334,77.5789748,17z/data=!4m10!1m2!2m1!1sXtreme+Tattoo+Studio!3m6!1s0x3bae159842013c71:0xa78e5f34e1efc4b4!8m2!3d12.9293334!4d77.5837384!15sChRYdHJlbWUgVGF0dG9vIFN0dWRpb1oWIhR4dHJlbWUgdGF0dG9vIHN0dWRpb5IBC3RhdHRvb19zaG9wmgEkQ2hkRFNVaE5NRzluUzBWSlEwRm5TVU53TjNSbGNUaG5SUkFC4AEA!16s%2Fg%2F11bwl3q68l?entry=ttu">
                    {about.address.name}
                  </a>
                </div>
              </div>
              {/* phone */}
              <div className="flex items-center gap-x-[10px]">
                <div>{about.phone.icon}</div>
                <div>{about.phone.number}</div>
              </div>
              <div className="flex items-center gap-x-[10px]">
                <div>{about.phone.icon}</div>
                <div>+918884888805</div>
              </div>
              {/* email */}
              <div className="flex items-center gap-x-[10px]">
                <div>{about.email.icon}</div>
                <div>{about.email.address}</div>
              </div>
              <div className="flex items-center gap-x-[10px]">
                <div>{about.email.icon}</div>
                <div>srini1826@gmail.com</div>
              </div>
            </div>
          </motion.div>
          {/* links */}
          <motion.div
            variants={fadeIn("up")}
            className="flex-1 flex flex-col xl:items-center"
          >
            <div>
              <div className="font-primary text-xl uppercase tracking-[0.08em] mb-6">
                {links.title}
              </div>
              <ul className="flex flex-col gap-y-4 text-[#DBDBDB]">
                {links.items.map((item, index) => {
                  // destructure item
                  const { href, name } = item;
                  return (
                    <li key={index}>
                      <a className="hover:text-white transition" href={href}>
                        {name}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          </motion.div>
          {/* program */}
          <motion.div variants={fadeIn("up")} className="flex-1">
            <div className="font-primary text-xl uppercase tracking-[0.08em] mb-6">
              {program.title}
            </div>
            <ul className="flex flex-col gap-y-4 text-[#DBDBDB]">
              {program.items.map((item, index) => {
                return <li key={index}>{item.name}</li>;
              })}
            </ul>
          </motion.div>
          <motion.div variants={fadeIn("up")} className="flex-1">
            <div className="font-primary text-xl uppercase tracking-[0.08em] mb-6">
              {program1.title}
            </div>
            <div className="flex gap-x-4 text-[#DBDBDB]">
              <div>
                <Socials />
              </div>
              <div>
                <a
                  target="blank"
                  href="https://api.whatsapp.com/send?phone=919886652084"
                >
                  <img className="w-[28px] pl-2" src="/whatsapp.png" alt="" />
                </a>
              </div>
              <div>
                <a target="blank" href="http://www.youtube.com/@srini1826">
                  <img className="w-[28px] pl-2" src="/youtube.png" alt="" />
                </a>
              </div>
            </div>
          </motion.div>
          {/* newsletter */}
          <motion.div variants={fadeIn("up")} className="flex-1 hidden">
            <div className="font-primary text-xl uppercase tracking-[0.08em] mb-6">
              {newsletter.title}
            </div>
            <div className="leading-relaxed mb-9 text-[#DBDBDB]">
              {newsletter.subtitle}
            </div>
            <form className="flex  justify-between items-start border-b border-[#B6B6B6]">
              <input
                className="outline-none placeholder:text-base italic placeholder:capitalize  bg-transparent pb-2"
                type="text"
                placeholder={newsletter.form.placeholder}
              />
              <button type="submit" className="text-2xl cursor-pointer">
                {newsletter.form.icon}
              </button>
            </form>
          </motion.div>
        </motion.div>
        <div className="text-center text-white mt-8 text-lg ">
          Designed and developed by &nbsp;
          <a className="underline" href="https://www.quantech.pro/">
            <strong>Quantech</strong>
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
